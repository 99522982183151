(() => {
  const app = {

    init() {
      svg4everybody();

      let sliderArticle;

      const winSize = app._getWinSize();

      const formbouncerjsMsg = {
        missingValue: {
          checkbox: 'Обязательное поле',
          radio: 'Обязательное поле',
          select: 'Обязательное поле',
          'select-multiple': 'Обязательное поле',
          default: 'Обязательное поле'
        }
      };

      const clickout = new clickOut.default({
        after: () => {
          const overlay = document.getElementById('overlay');
          overlay.classList.remove('is-active');
        }
      });

      MicroModal.init({
        openTrigger: 'data-modal-open',
        closeTrigger: 'data-modal-close',
        onShow: modal => {
          modal.addEventListener('click', e => {
            if ( !e.target.closest('[data-modal-close]') ) return;
            MicroModal.close(modal.id);
          });

          // закрываем открытые модалки
          const modals = Array.prototype.slice.call(
            document.querySelectorAll('.js-modal.is-open')
          );

          const activeModals = modals.map(item => {
            if (item.id != modal.id) {
              MicroModal.close(modal.id);
              // item.classList.remove('is-open');
              // item.setAttribute('aria-hidden', 'true');
            }
            return item;
          });

          const modalType = modal.dataset.modalType;

          // Video Modal
          if (modalType == 'video') {
            afterglow.getPlayer('modal-player').play();
          }

          // Team Modal
          if (modalType == 'team') {
            const accordionTeam = new Unitoggle.default({
              container: modal.querySelector('.js-team-person-accordion'),
            });
          }
        },
        onClose: modal => {
          const modalType = modal.dataset.modalType;

          if (modalType == 'video') {
            afterglow.getPlayer('modal-player').pause();
            afterglow.getPlayer('modal-player').currentTime(0);
          }
        }
      });

      if (document.querySelector('[data-formbouncer]')) {
        const validate = new Bouncer('[data-formbouncer]', {
          messages: formbouncerjsMsg
        });

        if (document.querySelector('.js-masked')) {
          const mask = Maska.create('.js-masked');
        }
      }

      // Header
      app._stickHeader();

      // Burger Toggler
      if (document.querySelector('#header')) {
        const burgerToggle = new Unitoggle.default({
          container: '#header-body',
          onOpen: (tab) => {
            const overlay = document.getElementById('overlay');
            overlay.classList.add('is-active');
          },
          onClose: (tab) => {
            const overlay = document.getElementById('overlay');
            overlay.classList.remove('is-active');
          }
        });
      }

      // About Accordions
      if (document.querySelector('#about-accordions')) {
        const accordionAbout = new Unitoggle.default({
          container: '#about-accordions'
        });
      }

      // Event List Accordions
      if (document.querySelector('#event-list-accordions')) {
        const accordionTeam = new Unitoggle.default({
          container: '#event-list-accordions'
        });
      }

      // Event Accordions
      if (document.querySelector('#event-single-accordions')) {
        const sliderEvent = new Swiper('#slider-event', {
          slidesPerView: 'auto',
          centeredSlides: true,
          updateOnImagesReady: true,
          loop: true,
          loopedSlides: 5,
          navigation: {
            prevEl: '#slider-event-prev',
            nextEl: '#slider-event-next',
          },
          pagination: {
            el: '#slider-event-bullets',
            type: 'fraction',
            modifierClass: 'slider-counter-',
            currentClass: 'current',
            totalClass: 'total'
          },
          on: {
            imagesReady: function() {
              app._setSlidesSize(this);
              setTimeout(() => {
                this.update();
              }, 350);
            },
            update: function() {
              app._setSlidesSize(this);
            }
          }
        });

        const accordionEvent = new Unitoggle.default({
          container: '#event-single-accordions',
          onOpen: function (tab) {
            if (tab.id == 'event-gallery') {
              sliderEvent.update();
              setTimeout(() => {
                sliderEvent.update();
              }, 300);
              sliderEvent.on('slideChange', function() {
                this.update();
              });
            }
          }
        });
      }

      // Team Letters
      if (document.querySelector('.js-letter-scroll') && winSize.w > 1023) {
        app._letterScroll();
      }

      // Article Gallery Slider
      if (document.querySelector('#slider-article')) {
        sliderArticle = new Swiper('#slider-article', {
          slidesPerView: 'auto',
          centeredSlides: true,
          loop: true,
          loopedSlides: 5,
          updateOnImagesReady: true,
          navigation: {
            prevEl: '#slider-article-prev',
            nextEl: '#slider-article-next',
          },
          pagination: {
            el: '#slider-article-bullets',
            type: 'fraction',
            modifierClass: 'slider-counter-',
            currentClass: 'current',
            totalClass: 'total'
          },
          on: {
            imagesReady: function() {
              app._setSlidesSize(this);
              setTimeout(() => {
                this.update();
              }, 350);
            }
          }
        });

        sliderArticle.on('slideChange', function() {
          this.update();
        });
      }

      // Faq Accordions
      if (document.querySelector('#accordions-faq')) {
        const faqAccordions = new Unitoggle.default({
          container: '#accordions-faq'
        });
      }

      // Ways Accordions
      if (document.querySelector('#accordions-ways')) {
        let waysMasonry;

        if (winSize.w > 1023) {
          waysMasonry = new Masonry(document.querySelector('#accordions-ways'), {
            itemSelector: '.masonry-layout-item',
            columnWidth: '.masonry-layout-item',
            percentPosition: true,
            horizontalOrder: true
          });
        }

        const waysAccordions = new Unitoggle.default({
          container: '#accordions-ways',
          onOpen: function (tab) {
            if (typeof waysMasonry === 'object') {
              waysMasonry.layout();
            }
          },
          onClose: function(tab) {
            if (typeof waysMasonry === 'object') {
              waysMasonry.layout();
            }
          }
        });
      }

      // Resize Actions
      window.addEventListener('resize', () => {
        const winSize = app._getWinSize();

        setTimeout(() => {
          // Update Header
          app._stickHeader();

          // Update Letter Scroll
          if (document.querySelector('.js-letter-scroll') && winSize.w > 1023) {
            app._letterScroll();
          }
        }, 500);
      });
    },

    _getWinSize() {
      const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      return {w, h};
    },

    _stickHeader() {
      if (document.querySelector('#header')) {
        const main = document.querySelector('#main');
        const header = document.querySelector('#header');
        const headerH = header.offsetHeight;

        main.style.paddingTop = '';

        if (main.classList.contains('is-offset')) {
          main.style.paddingTop = headerH + 'px';
        }

        if (document.querySelector('#hero')) {
          document.querySelector('#hero').style.paddingTop = headerH + 'px';
        }
      }
    },

    _setSlidesSize(sliderObj) {
      const winSize = app._getWinSize();

      const slides = Array.from(sliderObj.slides);

      slides.forEach(slide => {
        const img = slide.querySelector('img');
        img.style.height = '';
        slide.style.width = '';
        slide.classList.remove('is-cover');
      });

      // Horizontal Height
      const height = slides.map(slide => {
        const img = slide.querySelector('img');

        if (img && img.width >= img.height) {
          return img.height;
        } else {
          return 0;
        }
      });

      height.sort((a,b) => (a < b) ? 1 : -1);

      let paddings = 21 * 2;

      if (winSize.w < 1024) {
        paddings = 12 * 2;
      }

      slides.forEach(slide => {
        const img = slide.querySelector('img');
        img.style.height = height[0] + 'px';
        slide.style.width = (img.width + paddings) + 'px';
        slide.classList.add('is-cover');
      });
    },

    _setSliderSameHeight(sliderObject) {
      const wrapper = sliderObject.wrapperEl;
      setTimeout(() => {
        [].forEach.call(sliderObject.slides, function(slide) {
          slide.style.height = wrapper.clientHeight + "px";
        });
      }, 300);
    },

    _letterScroll() {
      const winSize = app._getWinSize();

      const letterContainers = document.querySelectorAll('.js-letter-scroll');

      [].forEach.call(letterContainers, element => {
        const letter = element.querySelector('.js-letter');
        const start = letter.offsetTop;
        const end = start + element.offsetHeight;
        element.setAttribute('data-start', start);
        element.setAttribute('data-end', end);
      });


      window.addEventListener('scroll', e => {
        const scroll = window.scrollY;

        [].forEach.call(letterContainers, element => {
          const letter = element.querySelector('.js-letter');
          letter.style.left = letter.offsetLeft + 'px';

          const start = element.dataset.start;
          const end = element.dataset.end;

          if (scroll >= start && scroll <= end) {
            letter.classList.add('is-active');

            const bottomEdge = end - (letter.offsetHeight * 4);

            if ( scroll < end && scroll > bottomEdge ) {
              letter.classList.add('is-bottom');
              letter.parentElement.style.position = 'relative';
              letter.style.left = '';
              letter.style.left = letter.offsetLeft + 'px';
            }

            if (scroll < end && scroll < bottomEdge) {
              letter.classList.remove('is-bottom');
              // letter.parentElement.style.position = '';
              letter.style.left = '';
              letter.style.left = letter.offsetLeft + 'px';
            }
          }
          if (scroll > end || scroll < start) {
            letter.classList.remove('is-active');
            // letter.parentElement.style.position = '';
          }
        });
      });
    }
  };

  document.addEventListener('DOMContentLoaded', app.init);
})();
