(() => {
  const animations = {

    init() {
      const winSize = animations._getWinSize();

      if (winSize.w >= 1200) {
        if (document.querySelector("#hero")) {
          animations._hero(document.querySelector("#hero"));
        }

        if (document.querySelector("#workshop-hero")) {
          animations._hero(document.querySelector("#workshop-hero"));
        }

        if (document.querySelector("#about-hero")) {
          animations._aboutHero();
        }

        if (document.querySelector('#cover-picture')) {
          animations._coverPictureScrollZoom();
        }

        if (document.querySelector('.js-fade-in')) {
          animations._fadeIn();
        }

        if (document.querySelector('.js-fade-up')) {
          animations._fadeUp();
        }

        if (document.querySelector('.js-overlay-img')) {
          animations._overlayImg();
        }

        if (document.querySelector('.js-move-line')) {
          animations._moveLines();
        }
      }
    },

    _getWinSize() {
      const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      return {w, h};
    },

    _hero(container) {
      const elems = container.querySelectorAll('.js-hero-animation');

      const controller = new ScrollMagic.Controller();
      const timeline = gsap.timeline({ defaults: {duration: 1.32, ease: "power1.out"} });

      const scene = new ScrollMagic.Scene({
        triggerElement: container,
        triggerHook: 1,
        reverse: true
      });

      let delay = '<1';

      [].forEach.call(elems, elem => {
        const direction = elem.dataset.direction;

        const options = {
          from: {
            autoAlpha: 0
          },
          to: {
            autoAlpha: 1
          }
        };

        if (direction == 'right') {
          options.from.x = -200;
          options.to.x = 0;
        }

        if (direction == 'left') {
          options.from.x = 200;
          options.to.x = 0;
        }

        if (direction == 'up') {
          options.from.y = 200;
          options.to.y = 0;
        }

        scene.setTween(gsap.from(elem, options.from));

        if (direction != 'none') {
          timeline.to(elem, options.to, "-=1");
        } else {
          timeline.to(elem, options.to, delay);
          delay = '<';
        }
      });

      scene.setTween(timeline).addTo(controller);
    },

    _aboutHero() {
      const controller = new ScrollMagic.Controller();

      const elem = document.querySelector('#about-hero');
      const title = document.querySelector("#about-hero-title");
      const centerEl = document.querySelector('#about-hero-center');

      const scale = 204 / 46;

      const scene = new ScrollMagic.Scene({
        triggerElement: '#about-hero-center',
        triggerHook: 0.5,
        duration: elem.offsetHeight / 2,
        reverse: true
      })
      .setTween( gsap.from(title,
        {
          position: 'absolute',
          y: `-${centerEl.offsetTop + title.offsetHeight * scale / 1.5}`,
          x: title.offsetWidth - (title.offsetWidth / 4),
          scale: scale,
          color: '#ffffff',
          ease: 'sine.in',
          duration: 20
        }
      ))
      .setTween(gsap.to(title,
        {
          position: 'relative',
          y: 0,
          x: 0,
          scale: 1,
          color: '#dc5050',
          ease: 'sine.in',
          duration: 20
        }
      ))
      .addTo(controller);
    },

    _fadeIn() {
      const elems = document.querySelectorAll('.js-fade-in');

      const controller = new ScrollMagic.Controller();

      [].forEach.call(elems, elem => {
        let delay = 0.25;

        if (elem.hasAttribute('data-delay')) {
          delay = parseFloat(elem.dataset.delay);
        }

        const scene = new ScrollMagic.Scene({
          triggerElement: elem,
          triggerHook: 1,
          reverse: true
        })
        .setTween(gsap.from(elem, {autoAlpha: 0}))
        .setTween(gsap.to(elem, {autoAlpha: 1, delay: delay, duration: 0.6, ease: 'sine.in'}))
        .addTo(controller);
      });
    },

    _fadeUp() {
      const elems = document.querySelectorAll('.js-fade-up');
      const controller = new ScrollMagic.Controller();

      [].forEach.call(elems, elem => {
        let delay = elem.dataset.delay;

        if (!delay) {
          delay = 0;
        }

        // globalDelay += parseFloat(delay);

        const scene = new ScrollMagic.Scene({
          triggerElement: elem,
          triggerHook: 1,
          reverse: true
        })
        .setTween(gsap.from(elem, {autoAlpha: 0, y: 50}))
        .setTween(gsap.to(elem, {autoAlpha: 1, y: 0, delay: delay, duration: 0.8, ease: 'power1.out'}))
        .addTo(controller);
      });
    },

    _moveLines() {
      const containers = document.querySelectorAll('.animated-lines');
      const controller = new ScrollMagic.Controller();
      const winSize = animations._getWinSize();

      [].forEach.call(containers, container => {
        const lines = container.querySelectorAll('.js-move-line');

        [].forEach.call(lines, elem => {
          const direction = elem.dataset.direction;
          let start = '-100%';
          // let end = '100%';
          let end = 0;
          let diff = 0;
          let delay = 0;

          if (elem.offsetWidth > winSize.w) {
            diff = parseInt((elem.offsetWidth / winSize.w) * 100);
          } else {
            delay = 0;
          }

          if (diff > 0) {
            start = `-${diff}%`;
            // end = `${diff}%`;
            end = 0;
          }

          if (direction == 'left') {
            start = '100%';
            // end = '-100%';
            end = 0;

            if (diff > 0) {
              start = `${diff}%`;
              // end = `-${diff}%`;
              end = 0;
            }
          }

          const scene = new ScrollMagic.Scene({
            triggerElement: container,
            triggerHook: 1,
            duration: winSize.h + elem.offsetHeight,
            reverse: true
          })
          .setTween(gsap.from(elem, {left: start}))
          .setTween(gsap.to(elem, {left: end, delay: 1, duration: 200, ease: 'power1.in'}))
          .addTo(controller);
        });
      });
    },

    _coverPictureScrollZoom() {
      const controller = new ScrollMagic.Controller();
      const duration = document.querySelector('#cover-picture').offsetHeight;
      let triggerEl = document.querySelector("#cover-picture-wrapper");

      if (!triggerEl) {
        triggerEl = document.querySelector("#cover-picture");
      }

      const scene = new ScrollMagic.Scene({
        triggerElement: triggerEl,
        duration: duration,
        triggerHook: 0,
        reverse: true
      })
      .setTween("#cover-picture-img", {scale: 1.1, opacity: 0.1})
      .addTo(controller);
    },

    _overlayImg() {
      const elems = document.querySelectorAll('.js-overlay-img');

      const controller = new ScrollMagic.Controller();

      [].forEach.call(elems, elem => {
        const lines = elem.querySelectorAll('.js-overlay-img-item');

        const scene = new ScrollMagic.Scene({
          triggerElement: elem,
          duration: 0,
          triggerHook: 1,
          reverse: true
        });

        const tl1 = gsap.timeline({ defaults: {duration: 0.3, ease: "none"} });

        [].forEach.call(lines, (line, index) => {
          if (index == 0 || index == 2) {
            tl1.to(line, {x: 0}, "-=0.05");
          }

          if (index == 1 || index == 3) {
            tl1.to(line, {x: 0, duration: 0.3}, "-=0.2");
          }
        });

        tl1.to(elem, {backgroundColor: "rgba(255,255,255,0)"});

        [].forEach.call(lines, (line, index) => {
          if (index == 0 || index == 2) {
            tl1.to(line, {x: '100%'}, "-=0.2");
          }

          if (index == 1 || index == 3) {
            tl1.to(line, {x: '100%', duration: 0.3}, "-=0.2");
          }
        });

        scene
          .setTween(tl1)
          .addTo(controller);
      });
    }
  };

  document.addEventListener('DOMContentLoaded', animations.init);
})();
